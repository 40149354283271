.container {
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    background-color: #333;
    color: #fff;
    padding: 25px;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    padding-top: 50px;
  }
  
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .main-heading {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .name {
    margin: 10px 0;
  }
  