.App {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    padding: 20px;
  }
  
  .category-bar {
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
    background-color: #f1f1f1;
  }
  
  .category-names {
    display: flex;
  }
  
  .category-name {
    flex: 0 0 auto;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 10px;
    background-color: #ddd;
    transition: background-color 0.3s ease;
  }
  
  .category-name.open {
    background-color: #aaa;
  }
  
  .question-table {
    width: 100%;
    border-collapse: collapse;
    border: 2px solid #ddd; /* Increased border width */
  }
  
  .question-table th,
  .question-table td {
    padding: 10px;
    text-align: left;
    border: 2px solid #ddd; /* Increased border width */
  }
  
  .question-table th {
    background-color: #f2f2f2;
  }
  
  .rating-dropdown,
  .comment-input {
    width: 90%;
    padding: 10px;
    border: 2px solid #ddd; /* Increased border width */
    border-radius: 5px;
    font-size: 14px;
  }
  
  .comment-input {
    resize: vertical;
  }
  
  .rating-dropdown:focus,
  .comment-input:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
  }
  
  /* Media Query for Responsive Design */
  @media screen and (max-width: 768px) {
    .category-names {
      display: block;
    }
  
    .category-name {
      margin: 5px 0;
    }
  }
  