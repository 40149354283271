html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.register-form {
  height: 100vh;
  background-image: url('/src/bg-login.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-form form {
  max-width: 800px;
  padding: 20px;
  border-radius: 10px;

}
.form-field-custom-dropdown{
  width:15vw;
}
.registration-button {

  width: 600px;
  background-color: #00AAE7;
}

.register-button {
  width: 600px;
  background-color: #00AAE7
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;

}

.form-field-custom-input {
  width: 20vw;
}

.custom-input {
  width: 22vw;
}

.form-field {
  flex: 1;
}

.MuiFormControl-root {
  height: 100%;
}

.MuiSelect-icon {
  top: calc(50% - 14px);
  right: 8px;
}

.account {
  margin-left: 15vw;
}

@media screen and (max-width: 320px) {
  fieldset {
    margin-left: -10px;
  }

  .form-row {
    flex-direction: column;
    gap: 30px;
  }

  .form-field-custom-input {
    width: 15vw;
  }
}

@media screen and (max-width: 768px) {
  fieldset {
    margin-top: 30px;
  }

  .form-field {
    flex-basis: 100%;
  }
  .form-field-custom-input {
    width: 55vw;
  }
}



@media screen and (max-width: 2560px) {
  .form-field-custom-input {
    width: 350px;
  }
}

@media screen and (max-width: 1440px) {
  .register-form {
    min-height: 100vh;
  }

  .form-field-custom-input {
    width: 25vw;
  }
}

@media screen and (max-width: 1024px) {
  .register-form {
    min-height: 100vh;
  }

  .form-field-custom-input {
    width: 35vw;
  }
}

@media screen and (max-width: 425px) {
  .login-form {
    margin-left: 50px;
    margin-top: 70px;
  }

  .form-row {
    flex-direction: column;
    gap: 20px;
  }

  .form-field-custom-input {
    width: 85vw;
  }
}

@media screen and (max-width: 375px) {
  .login-form {
    margin-left: 50px;
    margin-top: 100px;
  }

  .form-row {
    flex-direction: column;
    gap: 20px;
  }

  .form-field-custom-input {
    width: 90vw;
  }
}