.reset-pwd-container {
  background-image: url('/src/bg-login.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #e9ecef;
}
    
  .form-container {
    /* background-color: #282828; */
    align-items: center;
}


.reset-text{
  text-align: center;
  color: #0d0d0e;
  font-size: 30px;
}

.reset-fieldset {
  padding: 20px;
  background-color:white;
  width: 30%;
  height:max-content;
  border: none;
}



@media screen and (max-width: 1024px)
{
  .reset-text{
    font-size: 20px;
  }
}

