/* EmployeePortal.css */

.performance-rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: linear-gradient(to bottom, #dbb1db, #80c7e4);

  }
  
  .performance-rating h2 {
    margin-bottom: 20px;
  }
  
  .question {
    margin-bottom: 15px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    background-color: white;
  }
  
  .question p {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .rating input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 50px;
   
  }
  
  .comment input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 95%;
    margin-top: 5px;
    height: 60px; /* Decreased height for the comment box */
    background-color: rgb(214, 225, 230);
  }
  
  button[type='submit'] {
    padding: 10px 15px;
    background-color: #00AAE7;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
  }
  
  button[type='submit']:hover {
    background-color: #0056b3;
  }
  